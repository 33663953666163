<template>
  <v-dialog v-model="dialog" max-width="1000">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Nomination Application</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="6">
              <v-select
                  outlined :error="$v.nomination_application.user_id.$error"
                  dense :items="users" item-text="full_name" item-value="id"
                  v-model="nomination_application.user_id">
                <template v-slot:label>
                  User <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.user_id" >**{{errors.user_id[0]}}</span>

<!--              <span class="text-danger" v-if="$v.nomination_application.user_id.$error">This Title field is Required</span>-->
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select :items="nomination_categories" item-text="title" item-value="id"
                  outlined
                  dense
                  v-model="nomination_application.nomination_category_id">
                <template v-slot:label>
                  Nomination Category <span class="text-danger">*</span>
                </template>
              </v-select>
<!--              <span class="text-danger" v-if="errors.nomination_category_id" >**{{errors.nomination_category_id[0]}}</span>-->

<!--              <span class="text-danger" v-if="$v.nomination_application.nomination_category_id.$error">This Nomination Category field is Required</span>-->
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select :items="nomination_sub_classes" item-text="title" item-value="id"
                  outlined
                  dense
                  v-model="nomination_application.nomination_sub_class_id">
                <template v-slot:label>
                  Nomination Sub Class <span class="text-danger">*</span>
                </template>
              </v-select>
<!--              <span class="text-danger" v-if="errors.nomination_category_id" >**{{errors.nomination_category_id[0]}}</span>-->

<!--              <span class="text-danger" v-if="$v.nomination_application.nomination_category_id.$error">This Nomination Category field is Required</span>-->
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select :items="skill_occupations" item-text="title" item-value="id"
                  outlined
                  dense
                  v-model="nomination_application.skill_occupation_id">
                <template v-slot:label>
                  Skill Occupations <span class="text-danger">*</span>
                </template>
              </v-select>
<!--              <span class="text-danger" v-if="errors.nomination_category_id" >**{{errors.nomination_category_id[0]}}</span>-->

<!--              <span class="text-danger" v-if="$v.nomination_application.nomination_category_id.$error">This Nomination Category field is Required</span>-->
            </v-col>
            <v-col cols="12" sm="12" md="6">

                <v-autocomplete :loading="countryLoading" :filter="customFilter" :items="countries" @change="changeCountry()"
                                dense item-text="name" item-value="id"
                                label="Country " outlined
                                prepend-inner-icon="fas fa-globe-americas" v-model="nomination_application.applied_country_id">
                  <template v-slot:item="slotProps">
                    <i :class="`em em-flag-${slotProps.item.iso_2.toLowerCase()}`" class="mr-2"></i>
                    {{slotProps.item.title}}
                  </template>
                  <template v-slot:selection="data">
                    <i :class="`em em-flag-${data.item.iso_2.toLowerCase()}`" class="mr-2"></i>
                    {{data.item.title}}
                  </template>
                </v-autocomplete>


            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                    :items="states"
                    :loading="stateLoading"
                    @change="getAllCity()"
                    dense
                    item-text="name"
                    item-value="id"
                    label="States "
                    outlined
                    v-model="nomination_application.applied_state_id"
                >
                  <template v-slot:item="slotProps">
                    {{slotProps.item.name}}
                  </template>
                  <template v-slot:selection="data">

                    {{data.item.name}}
                  </template>
                </v-autocomplete>
            </v-col>



            <v-col cols="12" md="6">
              <v-menu
                  v-model="applied_date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined
                      v-model="nomination_application.applied_date"
                      label="Applied Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="nomination_application.applied_date"
                    @input="applied_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                  v-model="decision_date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined
                      v-model="nomination_application.decision_date"
                      label="Decision Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="nomination_application.decision_date"
                    @input="decision_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              Status
              <v-switch
                  v-model="nomination_application.is_active"
                  :label="nomination_application.is_active ? 'Active' : 'InActive'"
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog">
          Cancel
        </v-btn>

        <v-btn
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import CountryService from "@/services/country/CountryService";
import NominationApplication from "@/services/nomination/nomination-application/NominationApplication";
import NominationCategoryService from "@/services/nomination/nomination-category/NominationCategoryService";
import NominationSubclassService from "@/services/nomination/nomination-subclass/NominationSubclassService";
import SkillOccupationService from "@/services/skill-occupation/SkillOccupationService";
import StateService from "@/services/country/state/StateService";
import UserService from "@/services/user/UserService";
const user=new UserService;
const state = new StateService();
const skillOccupation=new SkillOccupationService;
const nominationSubClass=new NominationSubclassService;
const nominationCategory=new NominationCategoryService;
const nominationApplication=new NominationApplication;

const country=new CountryService();
export default {
  validations:{
    nomination_application:{
      user_id:{required},
    }
  },
  data(){
    return{
      title:'',
      dialog: false,
      edit: false,
      loading: false,
      errors:[],
      nomination_application:{
        user_id:'',
        nomination_category_id:'',
        nomination_sub_class_id:'',
        skill_occupation_id:'',
        applied_country_id:'',
        state_id:'',
        applied_date:'',
        decision_date:'',
        is_active:true,
      },
      applied_date_menu:false,
      decision_date_menu:false,
      types:[
        {value:'study',text:'Study'},
        {value:'visit',text:'Visit'},
        {value:'work',text:'Work'},
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
      },
      countries:[],
      nomination_categories:[],
      nomination_sub_classes:[],
      skill_occupations:[],
      countryLoading:false,
      stateLoading:false,
      states:[],
      users:[],
    }
  },
  methods:{
    closeDialog(){
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog() {

      this.dialog = true;
    },
    createNominationApplication(){
      this.openDialog();
      this.resetForm();
      this.title = "Create";
      this.edit=false;
    },
    editNominationApplication(item){
      this.openDialog();
      this.edit=true;
      this.nomination_application=item;
      this.title = "Edit";
    },
    resetForm(){
      this.nomination_application={
        user_id:'',
        description:'',
        applied_country_id:'',
        application_type:'',
        is_active:true,
      };
      this.errors=[];
    },

    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      nominationApplication
          .create(this.nomination_application)
          .then(response => {
            this.$snotify.success("Nomination Application created successfully");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      nominationApplication
          .update(this.nomination_application.id, this.nomination_application)
          .then(response => {
            this.$snotify.success("Nomination Application updated successfully");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })

    },
    getAllNominationCategory() {
      nominationCategory
      .getAllActive()
      .then(response=>{
        this.nomination_categories=response.data.data;
      })
    },
    getAllNominationSubClass() {
      nominationSubClass
      .getAllActive()
      .then(response=>{
        this.nomination_sub_classes=response.data.data;
      })
    },
    getAllSkillNomination() {
      skillOccupation
      .getAllActive()
      .then(response=>{
        this.skill_occupations=response.data.data;
      })
    },
    getCountries() {
      country
          .getAllCountry({'is_operating_country': 1})
          .then((response) => {
            this.countries = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
    },
    changeCountry() {

      this.getAllState()
    },
    getAllState() {
      this.stateLoading = true;
      state
          .all(this.nomination_application.applied_country_id)
          .then(response => {
            this.states = response.data.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.stateLoading = false;
          });
    },
    getAllUser()
    {
      user
      .all()
          .then(response => {
            console.log(response)
            this.users=response.data.users;
          })
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.title.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1
    },
  },
  mounted() {
    this.getCountries();
    this.getAllNominationCategory();
    this.getAllNominationSubClass();
    this.getAllSkillNomination();
    this.getAllUser()
  }
}
</script>
